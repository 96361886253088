.BackgroundImage {
  background-image: url("../images/Jogging.png");
  background-size: auto;
  background-size: 100%;
}

.imageBackground {
  width: 100%;
  height: auto;
}

.fieldImage {
  height: 800px;
  width: 100%;
}

.container {
  position: relative;
}

.mainHeader {
  color: #9780af;
  font-weight: 900;
}

.buttonBackground {
  background-color: #9780af;
}

.centered {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.topPadding {
  padding-top: 3%;
}

.centerText {
  padding: 10%;
  text-align: center;
}

.imageLogo {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  height: 100px;
  position: relative;
}

.purpleBackground {
  background-color: rgb(151, 128, 175);
}

.darkPurpleBackground {
  background-color: #372844;
}

.relative {
  position: relative;
}

.testimonialsSection {
  background-position: center;
  background-size: cover;
  position: relative;
  height: 10%;
}

.whiteText {
  color: white;
}

.phoneImageSize {
  width: 200px;
  height: auto;
}

.player-wrapper {
  height: auto;
}

.react-player > div {
  padding-right: 30px;
  position: absolute;
}
